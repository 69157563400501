@tailwind base;
@tailwind components;
@tailwind utilities;

* {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
 font-family: "Roboto", sans-serif;
 scroll-behavior: smooth;
}

html,body{
  scroll-behavior: smooth;
}

.active-navlink .MuiListItemButton-root {
  @apply bg-white text-black font-semibold rounded-md;
}
.active-navlink  svg{
  @apply  text-[#10439F];
}

.inactive-navlink .MuiListItemButton-root {
  @apply bg-transparent text-white;
}
.inactive-navlink .MuiListItemButton-root:hover {
  @apply bg-blue-200 text-white rounded-md;
}


.drawer ::-webkit-scrollbar {
  width: 3px;
  
}

.drawer ::-webkit-scrollbar-track {
  background-color: transparent;
  
}

.drawer ::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 1000px;
}

.drawer ::-webkit-scrollbar-thumb:hover {
  background: #7784be; 
}
/* pages classes */
.composeSMS{
  @apply flex flex-col md:flex-row gap-6 w-full lg:w-[85%] mx-auto
}

.custom-underline {
  position: relative;
  height: 0;
}

.custom-underline::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px; 
  height: 1px; 
  background: #2C4964; 
}

.custom-underline::after {
  content: '';
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: -2px; 
  height: 3px; 
  background: #1977CC; 
}

.slick-dots{
  @apply absolute bottom-0
}


.slick-dots li button::before{
  @apply text-[14px] 
}
.slick-dots li.slick-active button:before{
  @apply text-blue-600
}